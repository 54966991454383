import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { File } from 'components/uploader'
import { useAPI } from 'hooks/useAPI'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import { createEventRegistration, Request } from 'thunks/event-registrations/create'
import { isAPIError, isGenericAPIError, processValidationErrors } from 'utils/asyncThunk'
import { notifyError } from 'utils/notify'

import { Event, EventRegistration } from './type'
// @ts-ignore
import { eventRegistrationQuery } from './type?query'

export type FormData = {
  _: string
  variable_amount: number | undefined
  tickets: number
  event_answers: (string | string[])[]
  documents: File[]
  event_optional_addons: number[]
  payment_method_id: number | undefined
}

export default function useRegistrationForm(
  event: Event | undefined,
  onRegister: (registration: EventRegistration) => void
) {
  const schema = useMemo(() => {
    if (!event) return yup.object()

    const answers: yup.ISchema<unknown, any, any>[] = event.event_questions.map(q => {
      const v = q.checkbox ? yup.array(yup.string()) : yup.string()
      if (q.mandatory && !q.with_image) return v.required('A response is required')
      return v
    })

    return yup.object().shape({
      event_answers: yup.tuple(answers as any).required(),
    })
  }, [event])

  const resolver = useYupValidationResolver<yup.InferType<typeof schema>>(schema)
  const form = useForm<FormData>({
    resolver,
    defaultValues: {
      tickets: 1,
      event_optional_addons: [],
      event_answers: [],
      documents: [],
    },
  })

  useEffect(() => {
    if (!event) return

    form.setValue(
      'event_optional_addons',
      event.optional_addons.map(() => 0)
    )
  }, [event, form])

  const [register, { timer }] = useAPI<EventRegistration, Request>(createEventRegistration)
  const handleSubmit = useCallback(
    async (data: FormData) => {
      if (!event) return
      try {
        form.clearErrors()

        const [registration] = await register({
          query: eventRegistrationQuery,
          event_registration: {
            ...data,
            event_answers: event.event_questions.map((q, i) => ({
              ...q,
              answer: data.event_answers[i],
              document: data.documents[i],
            })),
            event_optional_addons: event.optional_addons.map((a, i) => ({
              ...a,
              quantity: data.event_optional_addons[i],
            })),
            tickets: event.maximum_tickets ? data.tickets : undefined,
            variable_amount: data.variable_amount || 0,
            event_id: event.id,
            payment_method_id: data.payment_method_id ?? undefined,
          },
        })

        form.reset()

        onRegister(registration)
      } catch (err) {
        if (isAPIError<'event_registration'>(err)) {
          processValidationErrors('event_registration', err, form.setError)
        } else if (isGenericAPIError(err)) {
          notifyError(err.error ?? 'unable to register')
        } else {
          notifyError(`error: ${(err as any).message}`)
        }
      }
    },
    [event, form, onRegister, register]
  )

  return [handleSubmit, { form, timer }] as const
}
