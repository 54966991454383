export function getStaffPositionMember(
  organization: {
    staff_members: {
      first_name?: string
      last_name?: string
      staff_position: {
        title: string
      }
    }[]
  },
  position: string
) {
  return organization.staff_members.find(m => m.staff_position.title.toUpperCase() === position.toUpperCase())
}

export function formatStaffPositionMemberName(member: { last_name?: string; first_name?: string }) {
  if (member.last_name && member.first_name) {
    return `${member.last_name}, ${member.first_name}`
  }

  if (member.last_name) return member.last_name
  if (member.first_name) return member.first_name

  return ''
}

export function getStaffPositionMemberName(
  organization: {
    staff_members: {
      staff_position: {
        title: string
      }
    }[]
  },
  position: string
) {
  const member = getStaffPositionMember(organization, position)

  if (member) {
    return formatStaffPositionMemberName(member)
  }

  return null
}

export function isNationalOrganization(organization: { federation: { id: number } }) {
  return _.includes([1, 6, 7, 10, 41, 44, 45, 46, 54, 61], organization.federation.id)
}
