export const reimbursementQuery = `
  reimbursement {
    id
    amount
    description
    status
    reason
    approved_at
    denied_at
    created_at
    document {
      id
      url
    }
  }
`
