import moment from 'moment'
import { useMemo } from 'react'

type Event = {
  maximum_tickets: number | undefined
  registration_closes_on: string | undefined
  data: {
    tickets_sold: number
  }
}

export default function useEventState(event: Event | undefined) {
  const areTicketsAvailable = useMemo(
    (): boolean => !event || event.maximum_tickets === undefined || event.maximum_tickets - event.data.tickets_sold > 0,
    [event]
  )

  const isClosed = useMemo((): boolean => !event || moment(event.registration_closes_on).isBefore(moment()), [event])

  return useMemo(() => ({ areTicketsAvailable, isClosed }), [areTicketsAvailable, isClosed])
}
