import { iconForMimeType } from 'helpers'
import { FC, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { FormData } from 'components/event/registration-form/_useRegistrationForm'
import { Event } from 'components/event/registration-form/type'
import ConnectedField from 'components/form/connected-field'
import { UploadFileModal } from 'components/modals'
import { FaButton } from 'components/utilities'
import { MimeType } from 'helpers/ext-helpers'
import { closeModal, openModal } from 'hooks/useModal'

import CheckboxAnswer from './_CheckboxAnswer'
import Label from './_Label'
import RadioAnswer from './_RadioAnswer'

type PreviewProps = {
  mimetype: MimeType
  name: string
}

const PreviewImage: FC<PreviewProps> = ({ mimetype, name }) => {
  const src = iconForMimeType(mimetype, name)
  return <img className="sm-btn-image p-l-1" src={src} alt="Preview" />
}

type Props = {
  idx: number
  question: Event['event_questions'][number]
  name: string
}

const EventQuestion: FC<Props> = ({ idx, question, name }) => {
  const { formState, getValues, setValue } = useFormContext<FormData>()

  const error = useMemo(() => {
    const match = name.match(/\.(\d+)$/)
    if (!match) throw new Error(`unable to generate error key: ${name}`)

    const key = `event_answers["${match[1]}"]`
    // @ts-ignore
    return formState.errors[key]?.message as string | undefined
  }, [name, formState])

  const documents = getValues('documents') || []

  return (
    <ConnectedField label={<Label question={question} />} name={name} error={error}>
      {({ value, onChange }) => {
        if (question.checkbox) {
          return <CheckboxAnswer question={question} value={(value || []) as string[]} onChange={onChange} />
        }

        if (question.select) {
          return <RadioAnswer question={question} value={value as string} onChange={onChange} />
        }

        return (
          <>
            {question.with_image ? (
              <div>
                <FaButton icon="cloud-upload" bsSize="sm" onClick={openModal('UploadFile', idx)}>
                  Upload document
                </FaButton>

                {documents[idx] && <PreviewImage {...documents[idx]} />}

                <UploadFileModal
                  id={idx}
                  noun="document"
                  mimetypes={['image/jpeg', 'image/png', 'application/pdf']}
                  onSuccess={f => {
                    setValue(`documents.${idx}`, f)
                    closeModal()
                  }}
                />
              </div>
            ) : (
              <input
                type="text"
                value={value}
                onChange={e => onChange(e.target.value)}
                className="form-control !-mb-0"
              />
            )}
          </>
        )
      }}
    </ConnectedField>
  )
}

export default EventQuestion
