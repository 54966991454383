import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { FaIcon } from 'components/utilities/font-awesome'

export type Props = {
  path: string
  icon: string
  backgroundIcon?: string
  isActive: boolean
  isDisabled?: boolean
  children: ReactNode
}

const Item: FC<Props> = ({ path, icon, backgroundIcon, isActive, isDisabled, children }) => {
  return (
    <li className={classNames(isActive && 'active')}>
      <Link to={path} className={classNames(isDisabled && 'disabled-click')}>
        <FaIcon {...{ icon, backgroundIcon }} />
        &nbsp; {children}
      </Link>
    </li>
  )
}

export default Item
