const adminRoutes = {
  root: '/admin/members',
  budgets: {
    root: '/admin/budgets',
    show: (id: number | ':id' = ':id') => `/admin/budgets/${id}`,
  },
  documentFolders: {
    show: (id: number | ':id' = ':id') => `/admin/document-folders/${id}`,
  },
  reports: {
    show: (id: number | ':id' = ':id', date?: string) =>
      date ? `/admin/reports/${id}/${date}` : `/admin/reports/${id}`,
  },
  gCapital: {
    root: '/admin/g-capital',
    show: (id: number | ':id' = ':id') => `/admin/bill-pay/${id}`,
  },
  gMoney: {
    setup: '/admin/g-money/setup',
  },
  cards: {
    show: (id: number | ':id' = ':id') => `/admin/cards/${id}`,
  },
  members: {
    show: (id: number | ':id' = ':id') => ({
      root: `/admin/members/${id}`,
      attendance: `/admin/members/${id}/attendance`,
    }),
  },
  organizationPaymentPlans: {
    show: (id: number | ':id' = ':id') => `/admin/organization-payment-plans/${id}`,
  },
  settings: {
    bankAccount: '/admin/settings/bank-account',
  },
  reportSubmissions: {
    root: '/admin/report-submissions',
  },
  federationTransactions: {
    root: '/admin/federation-transactions',
  },
  voting: {
    root: '/admin/voting',
    new: '/admin/voting/new',
    ended: '/admin/voting/ended',
    finished: (id: number | ':id' = ':id') => `/admin/voting/${id}/finished`,
    show: (id: number | ':id' = ':id') => `/admin/voting/${id}`,
  },
  requiredDocuments: {
    root: '/admin/required-documents',
    show: (id: number | ':id' = ':id') => `/admin/required-documents/${id}`,
    reporting: '/admin/required-documents/reporting',
    archived: '/admin/required-documents/archived',
  },
  attendance: {
    root: '/admin/attendance',
    meetings: {
      show: (id: number | ':id' = ':id') => `/admin/attendance/${id}`,
    },
  },
  events: {
    root: '/admin/events',
    new: '/admin/events/new',
    show: (id: number | ':id' = ':id') => `/admin/events/${id}`,
  },
  nationalOverview: {
    root: '/admin/national-overview',
  },
  reporting: {
    memberBalances: {
      balances: '/admin/reporting/member-balances/balances',
      charges: '/admin/reporting/member-balances/charges',
      credits: '/admin/reporting/member-balances/credits',
      payments: '/admin/reporting/member-balances/payments',
    },
  },
}

export default adminRoutes
