import classNames from 'classnames'
import { GCM_ENVIRONMENT } from 'helpers'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import r from 'routes'
import { useAuthSelector } from 'store'

import { GlobalSearchContext } from 'components/global-search/context'
import GlobalSearchModal from 'components/global-search/modal'
import MenuToggle from 'components/utilities/menu-toggle'

import logo from './logo.png'
import NavbarDesktop from './navbar/desktop'
import NavbarMobile from './navbar/mobile'
import Sidebar from './sidebar'
import Unmorph from './unmorph'

const noSidebarRoutes = [r.auth.logout, r.member.requiredDocuments.root]

type Props = {
  children: ReactNode
}

const AppWrapper: FC<Props> = ({ children }) => {
  const { token, user } = useAuthSelector()
  const [globalSearchIsOpen, setGlobalSearchIsOpen] = useState(false)

  const isMorphed = localStorage.getItem('rootToken') !== null
  const isProduction = GCM_ENVIRONMENT === 'production'

  const [isSidebarVisible, setIsSidebarVisible] = useState(false)

  const location = useLocation()
  useEffect(() => {
    setIsSidebarVisible(false)
  }, [location.pathname])

  const isAuthedUser = useMemo(() => {
    const paths = ['member', 'admin', 'parent', 'federation', 'alumni', 'super']
    const path = location.pathname.split('/')[1]
    return paths.includes(path)
  }, [location.pathname])

  if (!isAuthedUser) return <>{children}</>

  return (
    <GlobalSearchContext.Provider
      value={{
        isOpen: globalSearchIsOpen,
        toggle: () => setGlobalSearchIsOpen(!globalSearchIsOpen),
        close: () => setGlobalSearchIsOpen(false),
      }}
    >
      <div
        id="wrapper"
        className={classNames(
          user?.role,
          isProduction ? 'live' : 'dev',
          noSidebarRoutes.includes(location.pathname) && 'no-sidebar'
        )}
      >
        {isProduction ? null : <div className="is-not-production" />}

        <div className="navbar navbar-default hidden-print" role="navigation">
          <div className="container">
            <div className="navbar-header">
              <div className="navbar-toggle-sidebar" onClick={() => setIsSidebarVisible(!isSidebarVisible)}>
                <MenuToggle toggled={isSidebarVisible} />
              </div>

              <div className="navbar-brand">
                <Link to="/" className="logo">
                  <img src={logo} height={50} alt="" />
                </Link>
              </div>
            </div>

            <div className="navbar-header-right">
              <NavbarMobile />
              <NavbarDesktop />
            </div>
          </div>
        </div>

        {token && (
          <div
            className={classNames('sidebar hidden-print', {
              'sidebar__is-visible': isSidebarVisible,
            })}
          >
            <div className="sidebar-bg"></div>

            {isMorphed && <Unmorph />}

            <div className="sidebar-inner">
              <Sidebar />
              <div className="clearfix"></div>
            </div>
          </div>
        )}

        <div className={user ? 'content-page' : 'unauthenticated'}>{children}</div>

        <div className="mobile-footer visible-xs hidden-print">
          <hr />
          Greek Capital Management
        </div>
      </div>
      <GlobalSearchModal />
    </GlobalSearchContext.Provider>
  )
}

export default AppWrapper
