import { FC } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'

import Status from 'components/reimbursements/status'
import Table, { Column, downloadCSV } from 'components/table'
import {
  Action, ActionGroup, Body, ButtonBar, Content, EmptyState, Header, Loading, Portlet, PrintAction,
  TableAction,
} from 'components/utilities'
import { FaButton } from 'components/utilities/font-awesome'
import useActiveSidebarItem from 'hooks/useActiveSidebarItem'
import { useLegacyMember } from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { openModal } from 'hooks/useModal'
import { listReimbursements } from 'thunks/reimbursements/list'

import RequestReimbursementModal from './request-reimbursement-modal'
import { Reimbursement as ReimbursementType } from './type'
// @ts-ignore
import { reimbursementQuery } from './type?query'

const MemberReimbursements: FC = () => {
  const user = useLegacyMember()
  useActiveSidebarItem('reimbursements#member')

  const [reimbursements, { add: addReimbursement, timer }] = useList(listReimbursements, {
    query: reimbursementQuery,
    user_id: user.id,
  })

  if (!reimbursements) return <Loading />

  return (
    <div>
      <Content>
        <Grid>
          <div>
            <Header
              actions={
                <ActionGroup>
                  <Action icon="bullhorn" disabled={timer.isLoading} onClick={openModal('RequestReimbursement')}>
                    Request a reimbursement
                  </Action>
                </ActionGroup>
              }
              disabled={timer.isLoading}
            >
              Reimbursements
            </Header>

            <Portlet boxed>
              <Body table>
                <Table
                  csvName="Reimbursements"
                  data={reimbursements}
                  defaultSort={1}
                  defaultSortDir={false}
                  noResults={
                    <div>
                      <EmptyState
                        message="No reimbursements submitted yet"
                        action="Request a reimbursement"
                        modal="RequestReimbursement"
                      />
                    </div>
                  }
                >
                  <Column
                    width={20}
                    value="status"
                    formatter={(__: any, reimbursement: ReimbursementType) => <Status {...reimbursement} />}
                  >
                    Status
                  </Column>

                  <Column width={14} value="created_at" date="MMM D, YYYY">
                    Requested
                  </Column>

                  <Column width={23} value="description">
                    Description
                  </Column>

                  <Column width={18} value="amount" align="right" money>
                    Amount
                  </Column>

                  <Column
                    width={25}
                    value="document"
                    align="right"
                    formatter={(document: ReimbursementType['document']) =>
                      document ? (
                        <FaButton icon="cloud-download" onClick={() => window.open(document.url)}>
                          Download receipt
                        </FaButton>
                      ) : (
                        <em className="text-muted">no receipt uploaded</em>
                      )
                    }
                    noSort
                  />
                </Table>
              </Body>
            </Portlet>

            <Row>
              <Col sm={9} lg={9} />
              <Col sm={3} lg={3}>
                <ButtonBar className="footer-toolbar">
                  <PrintAction disabled={timer.isLoading} />
                  <TableAction icon="download" disabled={timer.isLoading} onClick={downloadCSV('Reimbursements')} />
                </ButtonBar>
              </Col>
            </Row>
          </div>
        </Grid>
      </Content>

      <RequestReimbursementModal onSuccess={reimbursement => addReimbursement(reimbursement)} />
    </div>
  )
}

export default MemberReimbursements
