import { FC } from 'react'

import Date from 'components/utilities/date'

import EventDate from './_EventDate'
import Photos from './_Photos'

type Props = {
  event: {
    id: number
    description: string
    registration_closes_on: string | undefined
    start_date: string | undefined
    end_date: string | undefined

    document_folder: {
      id: number
      documents: {
        id: number
        url: string
      }[]
    }
  }
}

const EventDetails: FC<Props> = ({ event }) => (
  <div>
    <p dangerouslySetInnerHTML={{ __html: event.description }} className="m-b-2" />

    {(event.start_date || event.end_date) && <EventDate startDate={event.start_date} endDate={event.end_date} />}

    <Photos event={event} />

    {event.registration_closes_on && (
      <p>
        Registration closes on <Date date={event.registration_closes_on} humanShort />.
      </p>
    )}
  </div>
)

export default EventDetails
