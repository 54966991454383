import { FC, useCallback, useMemo, useState } from 'react'
import { Button, Col, Modal, Radio } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { store } from 'store'
import * as yup from 'yup'

import { unwrapResult } from '@reduxjs/toolkit'

import { Field } from 'components/form'
import SubmitButton from 'components/form/button'
import ConnectedField from 'components/form/connected-field'
import Uploader from 'components/uploader'
import { MimeType } from 'helpers/ext-helpers'
import modal from 'helpers/modal'
import { useAPI } from 'hooks/useAPI'
import useLegacyContext from 'hooks/useLegacyContext'
import { closeModal } from 'hooks/useModal'
import useYupValidationResolver from 'hooks/useYupValidationResolver'
import { createBudgetExpense, ExpenseMethod, Request } from 'thunks/budget-expenses/create'
import { uploadDocumentUrl } from 'thunks/documents/upload-url'
import fetchRequiredActions from 'thunks/required-actions/fetch'
import notify, { notifyError } from 'utils/notify'

import { BudgetExpense } from './type'
// @ts-ignore
import { budgetExpenseQuery } from './type?query'

const paymentMethods = ['Cash', 'Check', 'Credit card'] as const

const schema = yup.object().shape({
  description: yup.string().required('Description is a required field'),
  cost: yup
    .number()
    .required('Cost is a required field')
    .positive('Cost must be greater than $0')
    .moreThan(0, 'Cost must be greater than $0'),
  method: yup
    .mixed()
    .oneOf(paymentMethods, `Method must be one of the following values: ${paymentMethods.join(', ')}`)
    .required('Method is a required field'),
})

type ValidationData = yup.InferType<typeof schema>

type Props = {
  budgetId: number
  organizationId?: number
  onCreate: (expense: BudgetExpense) => void
}

type FormData = {
  s3_path: string
  mimetype: MimeType | undefined
  description: string
  method: ExpenseMethod
  cost: number
}

const AddExpenseModal: FC<Props> = ({ budgetId, organizationId, onCreate }) => {
  const { user } = useLegacyContext()
  const isFederation = useMemo(() => user?.role === 'federation', [user?.role])
  const [showMoreOptions, setShowMoreOptions] = useState(false)
  const [isReimbursement, setIsReimbursement] = useState(false)

  const resolver = useYupValidationResolver<ValidationData>(schema)
  const form = useForm<FormData>({
    resolver: resolver,
    defaultValues: {
      description: '',
    },
  })

  const [create, { timer }] = useAPI<BudgetExpense, Request>(createBudgetExpense)
  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        const [expense] = await create({
          query: budgetExpenseQuery,
          budget_expense: {
            ...data,
            budget_id: budgetId,
          },
        })

        onCreate(expense)
        closeModal()
        notify(`${expense.description} has been added.`)

        if (expense.reimbursement) {
          store.dispatch(fetchRequiredActions({}))
        }
      } catch (err) {
        console.error('[UPLOAD BUDGET EXPENSE ERROR]', err)
        notifyError((err as any)?.message || 'There was an error creating the expense. Please try again.')
      }
    },
    [budgetId, create, onCreate]
  )

  return (
    <Modal show onHide={closeModal}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Add an expense</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="upload-wrapper">
              <Field
                label={
                  <div className="align-right">
                    Receipt <br />
                    <span className="text-muted">(optional)</span>
                  </div>
                }
                name="_receipt"
                inputSize={8}
              >
                <div className="upload-wrapper">
                  <Uploader
                    noun="receipt"
                    getS3Info={() =>
                      store.dispatch(uploadDocumentUrl({ organization_id: organizationId })).then(unwrapResult)
                    }
                    onSuccess={data => {
                      form.setValue('mimetype', data.mimetype)
                      form.setValue('s3_path', data.s3_path)
                      form.clearErrors(['mimetype', 's3_path'])
                    }}
                  />
                </div>
              </Field>

              <hr className="spacer-xs" />

              <ConnectedField
                name="description"
                label="Description"
                inputSize={7}
                maxLength={256}
                error={form.formState.errors.description?.message}
                autoFocus
              />

              <ConnectedField name="method" label="Method" inputSize={4} error={form.formState.errors.method?.message}>
                {field => (
                  <select {...field} className="form-control">
                    <option value={undefined}></option>
                    {paymentMethods.map(method => (
                      <option key={method} value={method}>
                        {method}
                      </option>
                    ))}
                  </select>
                )}
              </ConnectedField>

              <ConnectedField
                name="cost"
                label="Cost"
                type="number"
                min={0}
                inputSize={4}
                error={form.formState.errors.cost?.message}
                money
              />

              {isFederation && showMoreOptions && (
                <>
                  <hr />

                  <Field name="is_reimbursement" label="Reimbursement?">
                    <Radio checked={isReimbursement} onClick={() => setIsReimbursement(true)}>
                      Yes, I'm requesting reimbursement for this expense
                    </Radio>
                    <Radio checked={!isReimbursement} onClick={() => setIsReimbursement(false)}>
                      No, reimbursement isn't necessary
                    </Radio>
                  </Field>

                  <ConnectedField
                    name="reimbursement.method"
                    label="Reimbursement method?"
                    disabled={!isReimbursement}
                  />
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isFederation && (
              <Col md={5}>
                <Button onClick={() => setShowMoreOptions(!showMoreOptions)} className="pull-left">
                  {showMoreOptions ? 'Fewer options' : 'More options'}
                </Button>
              </Col>
            )}

            <SubmitButton timer={timer}>Record expense</SubmitButton>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default modal<Props>('AddBudgetExpense', AddExpenseModal)
