import { connect } from 'helpers'
import { FC } from 'react'

import { useLegacyAlumni } from 'hooks/useLegacyContext'
import { RequiredActionsState } from 'slices/requiredActions'

import SettingsTooltip from './alerts/settings-tooltip'
import Divider from './divider'
import Header from './header'
import Item from './item'
import OrganizationAdvisors from './organization-advisors'
import VotesAside from './votes-aside'

type Props = {
  requiredActions: RequiredActionsState
}

const AlumniMenu: FC<Props> = ({ requiredActions }) => {
  const {
    alumni: { organization },
  } = useLegacyAlumni()

  const { profile } = requiredActions

  return (
    <ul className="sidebar-menu">
      <Header>My Account</Header>
      <Item url="/alumni/donate" icon="bank">
        Donate
      </Item>
      <Item url="/alumni/settings" id="settings#alumni" icon="cog">
        Settings
        <SettingsTooltip profile={profile} />
      </Item>
      <Divider />
      {organization && <Header>{organization.federation.name}</Header>}
      <Item url="/alumni/calendar" icon="calendar">
        Calendar
      </Item>
      <Item url="/alumni/document-folders" icon="folder">
        Documents
      </Item>
      <Item url="/alumni/event-registrations" icon="file-text-o">
        Events
      </Item>
      <Item url="/alumni/forms" icon="file-text-o">
        Forms
      </Item>
      <Item url="/alumni/message-board" icon="comment">
        Message Board
      </Item>
      <Item url="/alumni/voting" icon="gavel" aside={<VotesAside votes={requiredActions.votes} />} asideType="primary">
        Voting
      </Item>
      {organization?.alumni_group_id && (
        <Item url={`/alumni/group-members/${organization?.alumni_group_id}`} icon="users">
          Group members
        </Item>
      )}
      <Divider />
      <Header>Alumni activities</Header>
      <Item url="/alumni/groups" icon="users">
        Groups
      </Item>
      <Divider />
      <OrganizationAdvisors />
    </ul>
  )
}

const mapStateToProps = (state: { requiredActions: RequiredActionsState }) => ({
  requiredActions: state.requiredActions,
})

export default connect(AlumniMenu, mapStateToProps)
