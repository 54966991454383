import cx from 'classnames'
import { extForMimeType } from 'helpers'
import React, { ReactNode } from 'react'

import { Dropzone, FaIcon, ImageCropper } from 'components/utilities'
import { MimeType } from 'helpers/ext-helpers'

import Preview from './preview'
import S3ProgressBar from './s3-progress-bar'

export type File = {
  name: string
  preview: string
  s3_path: string
  mimetype: MimeType
}

export function isUploadedFile(file: File | any): file is File {
  return file && file.s3_path
}

type Props = {
  noun: string
  mimeTypes: readonly MimeType[]
  preprocessor?: ReactNode | typeof ImageCropper
  getS3Info: () => Promise<{ data: { path: string } }>
  onStart?: () => void
  onSuccess: (data: File) => void
}

type State = {
  s3: { path: string } | null
  rejected: boolean
  state: 'preprocessing' | 'uploading' | 'saving' | 'pending'
  file: {
    name: string
    preview: string
    s3_path: string
    type: MimeType
  } | null
  percentage: number
}

class S3UploadBox extends React.Component<Props, State> {
  static defaultProps = {
    mimeTypes: [
      'application/pdf',
      'image/jpeg',
      'image/png',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/x-iwork-pages-sffpages',
      'application/x-iwork-numbers-sffnumbers',
    ],
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      state: 'pending',
      s3: null,
      file: null,
      rejected: false,
      percentage: 0,
    }

    props.getS3Info().then(({ data: s3 }: { data: { path: string } }) => this.setState({ s3 }))
  }

  onDrop = (files: any[]) => {
    if (files.length > 0) {
      this.setState({
        state: this.props.preprocessor ? 'preprocessing' : 'uploading',
        file: files[0],
        rejected: false,
      })
      if (this.props.onStart) {
        this.props.onStart()
      }
    }
  }

  allowedExtensions = () => this.props.mimeTypes.map(m => `.${extForMimeType(m)}`)

  onDropRejected = () => {
    this.setState({ rejected: true })
  }

  onDragEnter = () => {
    this.setState({ rejected: false })
  }

  onSuccess = () => {
    this.setState(
      {
        state: 'saving',
      },
      () =>
        this.props.onSuccess({
          name: this.state.file?.name ? this.state.file.name : '',
          preview: this.state.file?.preview ? this.state.file.preview : '',
          s3_path: this.state.s3?.path ? this.state.s3.path : '',
          mimetype: this.state.file?.type ? this.state.file.type : 'binary/octet-stream',
        })
    )
  }

  onPreprocessorSuccess = (file: State['file']) => {
    this.setState({
      file,
      state: 'uploading',
    })
  }

  renderPending = () => {
    const { mimeTypes, noun } = this.props
    const { s3, rejected } = this.state
    const allowedExtensions = this.allowedExtensions()

    return (
      // @ts-ignore
      <Dropzone
        multiple={false}
        maxSize={10000000}
        activeClassName="accepted-file-hovering"
        rejectClassName="rejected-file-hovering"
        accept={(mimeTypes as string[]).concat(allowedExtensions).join(',')}
        onDrop={this.onDrop}
        onDropRejected={this.onDropRejected}
        onDragEnter={this.onDragEnter}
        className={cx('file-drag-and-drop', {
          'ready-for-upload': !_.isNil(s3),
          'rejected-file-hovering': rejected,
        })}
      >
        <FaIcon cloud-upload />
        <h5>
          Drag &amp; drop <br />a {noun} here
        </h5>
        or <a className="text-bold">browse</a>
        {rejected && <p className="text-danger m-b-0 m-t-1">Allowed extensions are: {allowedExtensions.join(', ')}</p>}
      </Dropzone>
    )
  }

  renderProgress = () => {
    const { file, s3 } = this.state

    return (
      <div>
        <Preview file={file} />
        <S3ProgressBar s3={s3} file={file} sendDimensions showComplete onSuccess={this.onSuccess} />
      </div>
    )
  }

  render() {
    const { state, file } = this.state
    const { preprocessor: Preprocessor } = this.props

    if (state === 'pending') {
      return this.renderPending()
    } else if (state === 'preprocessing') {
      // @ts-ignore
      return <Preprocessor file={file} onSuccess={this.onPreprocessorSuccess} />
    } else if (state === 'uploading' || state === 'saving') {
      return this.renderProgress()
    }

    return <Preview file={file} />
  }
}

export default S3UploadBox
