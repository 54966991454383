import { createAsyncThunk } from '@reduxjs/toolkit'

export type Request = {
  uri: string
}

export type Response = Record<string, never>

const unmorph = createAsyncThunk<Response, Request>('auth/unmorph', async ({ uri }) => {
  const token = localStorage.getItem('rootToken')
  localStorage.removeItem('rootToken')
  localStorage.removeItem('rootRole')
  localStorage.setItem('token', token || '')
  window.location.href = uri

  return {}
})

export default unmorph
