import { FC, ReactNode } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppState } from 'store'

import { FaIcon } from 'components/utilities/font-awesome'

type OwnProps = {
  id?: string
  isActive?: boolean
  children: ReactNode
}

type Props = OwnProps & {
  icon?: string
  backgroundIcon?: string
  url?: string
  aside?: ReactNode
  asideType?: 'primary'
  multiLine?: boolean
  onClick?: () => void
}

const Item: FC<Props> = ({ isActive, icon, backgroundIcon, url, onClick, children, aside, asideType, multiLine }) => {
  const asideEl = aside ? (
    <span key="asideEl" className={`label label-${asideType || 'secondary'} pull-right`}>
      {aside}
    </span>
  ) : null

  const className = (isActive ? 'active' : '') + (multiLine ? ' multi-line' : '')
  const childNodes = multiLine ? <span key="childNodes">{children}</span> : children

  const iconEl = backgroundIcon ? (
    <span key="iconEl" className="fa-stack">
      <i className={`fa fa-stack-2x fa-${backgroundIcon}`}></i>
      <i className={`fa fa-stack-1x fa-${icon}`}></i>
    </span>
  ) : (
    <FaIcon key="iconEl" icon={icon} />
  )

  const itemChildren = [iconEl, childNodes, asideEl]

  let link
  if (!url) {
    link = <a onClick={onClick} children={itemChildren} className="c-pointer" />
  } else if (url.match(/^(mail|tel):/)) {
    link = <a href={url} children={itemChildren} />
  } else {
    link = <Link to={url} children={itemChildren} />
  }

  return <li className={className}>{link}</li>
}

function mapStateToProps(state: AppState, { isActive, id, children }: OwnProps) {
  return {
    isActive: isActive || state.ui.activeSidebarItem === _.defaultTo(id, children),
  }
}

export default connect(mapStateToProps)(Item)
