import { FC } from 'react'
import { Label, OverlayTrigger, Tooltip } from 'react-bootstrap'

import Date from 'components/utilities/date'

export type Reimbursement = {
  id: number
  status: 'pending' | 'approved' | 'denied'
  approved_at?: string
  denied_at?: string
  reason?: string
}

type Props = {
  reimbursement: Reimbursement
  onPendingClick: () => void
}

const ReimbursementStatus: FC<Props> = ({ reimbursement, onPendingClick }) => {
  switch (reimbursement.status) {
    case 'pending':
      return (
        <Label bsStyle="warning" className="c-pointer" onClick={onPendingClick}>
          Pending reimbursement
        </Label>
      )
    case 'approved':
      return (
        <Label bsStyle="success">
          Reimbursed on <Date short date={reimbursement.approved_at!} />
        </Label>
      )
    case 'denied':
      return (
        <span className="d-inline-block">
          <Label bsStyle="danger">
            Denied on <Date short date={reimbursement.denied_at!} />
          </Label>
          &nbsp;
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="tooltip">reason for denial: {reimbursement.reason}</Tooltip>}
          >
            <i>
              <small className="text-muted"> why?</small>
            </i>
          </OverlayTrigger>
        </span>
      )
    default:
      return null
  }
}

export default ReimbursementStatus
