const federationRoutes = {
  root: '/federation',
  alumni: {
    show: (id: number | ':id' = ':id') => `/federation/alumni/${id}`,
  },
  attendance: {
    root: '/federation/attendance',
    meetings: {
      show: (id: number | ':id' = ':id') => `/federation/attendance/${id}`,
    },
  },
  billPay: {
    root: '/federation/bill-pay',
    show: (id: number | ':id' = ':id') => `/federation/bill-pay/${id}`,
  },
  billingCategories: {
    show: (id: number | ':id' = ':id') => `/federation/billing-categories/${id}`,
    outstanding: (id: number | ':id' = ':id') => `/federation/billing-categories/${id}/outstanding`,
  },
  budgets: {
    root: '/federation/budgets',
    show: (id: number | ':id' = ':id') => `/federation/budgets/${id}`,
  },
  contributionTrackers: {
    root: '/federation/contribution-trackers',
    new: (step?: string | ':step') => `/federation/contribution-trackers/new${step ? `/${step}` : ''}`,
    show: (id: number | ':id' = ':id', orgId?: number | ':orgId') =>
      `/federation/contribution-trackers/${id}${orgId ? `/${orgId}` : ''}`,
    edit: (id: number | ':id' = ':id') => `/federation/contribution-trackers/${id}/edit`,
  },
  donations: {
    root: '/federation/donations',
  },
  voting: {
    root: '/federation/voting',
    new: '/federation/voting/new',
    ended: '/federation/voting/ended',
    finished: (id: number | ':id' = ':id') => `/federation/voting/${id}/finished`,
    show: (id: number | ':id' = ':id') => `/federation/voting/${id}`,
  },
  committees: {
    root: '/federation/committees',
  },
  documentFolders: {
    root: '/federation/document-folders',
    show: (id: number | ':id' = ':id') => `/federation/document-folders/${id}`,
  },
  events: {
    root: '/federation/events',
  },
  eventRegistrations: {
    show: (id: number | ':id' = ':id') => `/federation/event-registrations/${id}`,
  },
  executiveBoards: {
    root: '/federation/executive-boards',
    documentFolders: {
      root: '/federation/executive-boards/document-folders',
      show: (id: number | ':id' = ':id') => `/federation/executive-boards/document-folders/${id}`,
    },
  },
  groups: {
    root: '/federation/groups',
    show: (id: number | ':id' = ':id') => `/federation/groups/${id}`,
  },
  messageCategories: {
    root: '/federation/message-board',
    show: (id: number | ':id' = ':id') => `/federation/message-categories/${id}`,
    thread: (categoryId: number | ':id' = ':id', threadId: number | string = ':threadId') =>
      `/federation/message-categories/${categoryId}/thread/${threadId}`,
    folder: (id: number | ':id' = ':id', folderId: number | string = ':folderId') =>
      `/federation/message-categories/${id}/folder/${folderId}`,
  },
  members: {
    root: '/federation/members',
    show: (id: number | ':id' = ':id') => ({
      root: `/federation/members/${id}`,
      attendance: `/federation/members/${id}/attendance`,
    }),
  },
  reports: {
    root: '/federation/reports',
  },
  financials: {
    summary: '/federation/financials/summary',
    chapters: '/federation/financials/chapters',
    trends: '/federation/financials/trends',
  },
  organizations: {
    root: '/federation/organizations',
    show: (id: number | string = ':id') => ({
      root: `/federation/organizations/${id}`,
      billPay: {
        root: `/federation/organizations/${id}/bill-pay`,
        show: (accountId: number) => `/federation/organizations/${id}/bill-pay/${accountId}`,
      },
      executiveBoard: `/federation/organizations/${id}/executive-board`,
      financials: `/federation/organizations/${id}/financials`,
      members: `/federation/organizations/${id}/members`,
      transactions: `/federation/organizations/${id}/transactions`,
      paymentPlan: `/federation/organizations/${id}/payment-plan`,
    }),
  },
  settings: {
    root: '/federation/settings',
    distributionLists: {
      root: '/federation/settings/distribution-lists',
      show: (id: number | string = ':id') => `/federation/settings/distribution-lists/${id}`,
      new: '/federation/settings/distribution-lists/new',
    },
  },
}

export default federationRoutes
