import { formatName, isGranted } from 'helpers'
import { forwardRef, ReactElement, useCallback, useMemo } from 'react'
import { ButtonGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import r from 'routes'

import { faLink } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import createTable, { TableRef } from 'components/table-v2'
import { Date, FaButton, Label, Money, Name } from 'components/utilities'
import { EmptyStateProps } from 'components/utilities/empty-state'
import useLegacyContext from 'hooks/useLegacyContext'
import { openModal } from 'hooks/useModal'
import { BUDGET_APPROVAL } from 'resources/feature'
import { User } from 'types/user'
import { isAdmin } from 'types/user-functions'
import formatMoney from 'utils/format-money'
import formatDate from 'utils/format_date'

import renderPreview from '../receipt-detail-button'
import ReimbursementStatus from '../reimbursement-status'
import { Budget } from './type'

const { Table, Header, Column, Actions, Body, FirstRow, Row, Cell } = createTable<Budget['budget_expenses'][number]>()

type Props = {
  budget: Budget
  budgetStartDate: string
  noResults: ReactElement<EmptyStateProps>
}

const BudgetExpensesList = forwardRef<TableRef, Props>(({ budget, budgetStartDate, noResults }, ref) => {
  const { user } = useLegacyContext()

  const isReadonly = useMemo(() => ['member', 'alumni'].includes(user.role), [user])

  const hasApprovalPermission = useCallback(
    () => user.role === 'federation' && isGranted(user, BUDGET_APPROVAL),
    [user]
  )

  const getMemberLink = useCallback(
    ({ id }: Pick<User, 'id'>) => {
      let prefix = '/member'
      if (user.role === 'federation') {
        prefix = '/federation'
      } else if (user.role === 'admin') {
        prefix = '/admin'
      }

      return `${prefix}/members/${id}`
    },
    [user]
  )

  const expenses = useMemo(
    () => budget.budget_expenses.sort((a, b) => (a.created_at < b.created_at ? -1 : 1)),
    [budget.budget_expenses]
  )

  const balances = useMemo(() => {
    if (!budget) return {}

    var balance = budget.starting_balance?.amount ?? 0
    return budget.budget_expenses.reduce((acc, expense) => {
      return {
        ...acc,
        [expense.id]: (balance -= expense.cost),
      }
    }, {} as Record<number, number>)
  }, [budget])

  return (
    <Table ref={ref} data={expenses} noSort empty={noResults}>
      <Header>
        <Column width={15}>Date</Column>
        <Column width={13}>Created by</Column>
        <Column width={25}>Description</Column>
        <Column width={11}>Method</Column>
        <Column width={12} align="right">
          Amount
        </Column>
        <Column width={12} align="right">
          Remaining
        </Column>
        {!isReadonly && <Actions width={17} />}
      </Header>
      <FirstRow>
        <tr>
          <td data-value={formatDate(budgetStartDate, 'short')}>
            <Date short date={budgetStartDate} />
          </td>
          <td>
            {budget.created_by ? (
              <Name
                user={budget.created_by}
                to={budget.created_by?.member ? getMemberLink(budget.created_by.member) : undefined}
              />
            ) : (
              'n/a'
            )}
          </td>
          <td>Starting budget</td>
          <td className="text-muted">n/a</td>
          <td className="text-right" data-value={formatMoney(budget.starting_balance?.amount ?? 0)}>
            <Money praise amount={budget.starting_balance?.amount ?? 0} />
          </td>
          <td className="text-right" data-value={formatMoney(budget.starting_balance?.amount ?? 0)}>
            <Money amount={budget.starting_balance?.amount ?? 0} />
          </td>
          {isReadonly || (
            <td className="text-right">
              <ButtonGroup bsSize="sm">
                <FaButton icon="pencil" onClick={openModal('Input')} />
                <FaButton icon="trash" disabled />
              </ButtonGroup>
            </td>
          )}
        </tr>
      </FirstRow>
      <Body>
        {expense => (
          <Row key={expense.id} id={expense.id}>
            <Cell value={formatDate(expense.created_at, 'short')}>
              <Date short date={expense.created_at} />
            </Cell>
            <Cell value={expense.created_by ? formatName(expense.created_by) : 'n/a'}>
              {expense.created_by ? (
                <Name
                  user={expense.created_by}
                  to={expense.created_by?.member && getMemberLink(expense.created_by.member)}
                />
              ) : (
                <span className="text-muted">n/a</span>
              )}
            </Cell>
            <Cell>
              {expense.description}

              {expense.reimbursement && hasApprovalPermission() && (
                <div className="m-t-025">
                  <ReimbursementStatus
                    reimbursement={expense.reimbursement}
                    onPendingClick={openModal('ReviewFederationReimbursement', String(expense.reimbursement.id))}
                  />
                </div>
              )}

              {expense.issued_card_transaction && (
                <Link
                  to={r[isAdmin(user) ? 'admin' : 'member'].cards.show(expense.issued_card_transaction.issued_card.id)}
                >
                  <Label info className="m-l-1">
                    <FontAwesomeIcon icon={faLink} />
                  </Label>
                </Link>
              )}
            </Cell>
            <Cell>{expense.method || <span className="text-muted">n/a</span>}</Cell>
            <Cell value={formatMoney(expense.cost)}>
              <Money amount={expense.cost} praise plus />
            </Cell>
            <Cell value={formatMoney(balances[expense.id])}>
              <Money amount={balances[expense.id]} warning />
            </Cell>
            {!isReadonly && (
              <Cell>
                <ButtonGroup>
                  {expense.document && renderPreview(expense.document)}
                  <>
                    <FaButton
                      icon="pencil"
                      bsSize="sm"
                      onClick={openModal(
                        expense.cost > 0 ? 'UpdateBudgetExpense' : 'UpdateBudgetCredit',
                        String(expense.id)
                      )}
                    />
                    <FaButton
                      icon="trash"
                      bsSize="sm"
                      onClick={openModal('Confirm:ArchiveExpense', String(expense.id))}
                    />
                  </>
                </ButtonGroup>
              </Cell>
            )}
          </Row>
        )}
      </Body>
    </Table>
  )
})

export default BudgetExpensesList
