import { FC, useEffect, useMemo } from 'react'
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom'
import r from 'routes'
import { useAuthSelector } from 'store'

import { rootPathFromRole } from 'pages/Unauthenticated/RootRedirect'
import { Feature, FederationFeature } from 'types/feature'
import { Role } from 'types/user'
import { isMember, isParent } from 'types/user-functions'

type AnyFeature = Feature | FederationFeature

type Props = {
  role?: Role
  roles?: Role[]
  feature?: AnyFeature
}

const AppRoute: FC<Props & RouteProps> = ({ feature, role, roles, ...rest }) => {
  const { token, user } = useAuthSelector()

  const history = useHistory()
  useEffect(() => {
    if (token) return
    history.push(r.auth.logout)
  }, [token, history])

  useEffect(() => {
    if (role || roles || feature) return
    throw new Error(`AppRoute ${rest.path} is missing a role, roles, or feature prop.`)
  }, [role, roles, feature, rest.path])

  const features: AnyFeature[] | undefined = useMemo(() => {
    if (!user) return
    if (user.role === 'member') return user.member.data.all_features
    if (user.role === 'federation') return user.all_features
  }, [user])

  const render = useMemo((): boolean => {
    if (!user) return false

    if (feature && features?.includes(feature)) return true

    if (role && role !== user.role) return false
    if (roles && !roles.includes(user.role)) return false

    return true
  }, [role, roles, feature, user, features])

  /**
   * TMP: Redirect back to my.
   */
  // useEffect(() => {
  //   if (_env_.GCM_ENV !== 'production') return
  //   if ((rest.path as string).match(/^\/super\//)) return

  //   if (!user) return
  //   if (isAdmin(user)) return
  //   if (isMember(user)) return
  //   if (isParent(user)) return
  //   if (isAlumni(user)) return

  //   try {
  //     const rootToken = localStorage.getItem('rootToken')
  //     if (rootToken) {
  //       // const rootUserId = JSON.parse(atob(rootToken.split('.')[1])).sub
  //       // if (rootUserId === 2) return
  //     }
  //   } catch (e) {}

  //   let uri = window.location.pathname
  //   if (uri === '/member/balance') {
  //     uri = '/balance'
  //   }
  //   const payload = btoa(JSON.stringify(localStorage))
  //   const base = _env_.GCM_ENV === 'production' ? 'https://my.greekcapitalmanagement.com' : 'https://gc.m:3000'
  //   window.location.href = `${base}${uri}?ls=${payload}`
  // }, [user, rest.path])
  /**
   *
   */

  if (!user) return null

  if ((isMember(user) || isParent(user)) && user.data.has_required_documents) {
    const path = r[isMember(user) ? 'member' : 'parent'].requiredDocuments.root
    if (!history.location.pathname.startsWith(path)) {
      return <Redirect to={path} />
    }
  }

  if (render) return <Route {...rest} />

  const path = rootPathFromRole(user.role)
  return <Redirect to={path} />
}

export default AppRoute
