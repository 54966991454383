import adminRoutes from 'routes/_admin'
import alumniRoutes from 'routes/_alumni'
import federationRoutes from 'routes/_federation'
import memberRoutes from 'routes/_member'
import parentRoutes from 'routes/_parent'
import superRoutes from 'routes/_super'

const r = {
  root: '/',
  error: '/error',
  auth: {
    login: '/login',
    logout: '/logout',
  },
  member: memberRoutes,
  admin: adminRoutes,
  parent: parentRoutes,
  alumni: alumniRoutes,
  federation: federationRoutes,
  super: superRoutes,
  public: {
    eventRegistrations: {
      show: (id: number | string = ':id') => `/register/${id}`,
      confirm: (id: number | string = ':id') => `/register/${id}/confirm`,
    },
  },
}

export default r
