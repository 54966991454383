import classNames from 'classnames'
import { FC, useCallback, useEffect } from 'react'
import { Alert, Button, Col, Radio, Row } from 'react-bootstrap'
import { FormProvider } from 'react-hook-form'
import NumericInput from 'react-numeric-input'

import ConnectedField from 'components/form/connected-field'
import { AddCreditCardModal } from 'components/modals'
import { Money, PaymentMethod } from 'components/utilities'
import useLegacyContext from 'hooks/useLegacyContext'
import useList from 'hooks/useList'
import { openModal } from 'hooks/useModal'
import NoMoreTickets from 'pages/Unauthenticated/Register/_NoMoreTickets'
import { listPaymentMethods, Request as ListRequest } from 'thunks/payment-methods/list'

import RegistrationAlert from './_RegistrationAlert'
import useRegistrationForm from './_useRegistrationForm'
import AddOns from './shared/_AddOns'
import useEventState from './shared/_useEventState'
import useRegistrationCost from './shared/_useRegistrationCost'
import CustomEventQuestions from './shared/EventQuestions'

import { Event, EventRegistration, PaymentMethod as PaymentMethodType } from './type'
// @ts-ignore
import { paymentMethodQuery } from './type?query'

type Props = {
  event: Event
  onRegister: (registration: EventRegistration) => void
}

const EventRegistrationForm: FC<Props> = ({ event, onRegister }) => {
  const { user } = useLegacyContext()

  const { areTicketsAvailable, isClosed } = useEventState(event)

  const [handleSubmit, { form, timer }] = useRegistrationForm(event, onRegister)

  console.log('errs', form.formState.errors)

  const [paymentMethods, { prepend }] = useList<PaymentMethodType, ListRequest>(listPaymentMethods, {
    query: paymentMethodQuery,
    user_id: user.id,
  })
  useEffect(() => {
    if (!paymentMethods || paymentMethods.length === 0) return

    form.setValue('payment_method_id', paymentMethods[0].id)
  }, [paymentMethods, form])

  const handleCreditCard = useCallback(
    (paymentMethod: PaymentMethodType) => {
      prepend(paymentMethod)
      form.setValue('payment_method_id', paymentMethod.id)
    },
    [form, prepend]
  )

  useEffect(() => {
    if (!event) return

    const answers: (string | string[])[] = event.event_questions.map(question => (question.checkbox ? [] : ''))
    form.setValue('event_answers', answers)
  }, [event, form])

  const values = form.watch()
  const totalCost = useRegistrationCost(event, values)

  if (isClosed && event.registration_closes_on) {
    return <RegistrationAlert event={event} />
  }

  if (!areTicketsAvailable && event.maximum_tickets) {
    return <NoMoreTickets event={{ ...event, maximum_tickets: event.maximum_tickets }} />
  }

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <CustomEventQuestions questions={event.event_questions} />

          {event.maximum_tickets && (
            <>
              <hr />

              <ConnectedField
                label={
                  <div>
                    Number of tickets:
                    {event.cost && <Money amount={event.cost} className="d-block text-muted" />}
                  </div>
                }
                name="tickets"
                type="number"
                min={1}
                max={event.maximum_tickets - event.data.tickets_sold}
                defaultValue={1}
                inputSize={2}
              >
                {({ value, onChange }) => (
                  <NumericInput mobile min={1} value={value} onChange={onChange} className="form-control" />
                )}
              </ConnectedField>
            </>
          )}

          {event.cost_type === 'variable' && (
            <>
              <hr />

              <ConnectedField
                label={
                  <span>
                    <span className="text-danger">* </span>
                    Amount to pay:
                  </span>
                }
                name="variable_amount"
                hint="You decide how much to pay to attend this event."
                money
                inputSize={4}
              />
            </>
          )}

          <AddOns addons={event.optional_addons} />

          {!!totalCost && (
            <>
              <hr />
              <ConnectedField label="Payment method" name="payment_method_id">
                {({ value, onChange }) => (
                  <>
                    {(paymentMethods || []).map(paymentMethod => (
                      <Radio
                        name="payment_method_id"
                        checked={value === paymentMethod.id}
                        onChange={() => onChange(paymentMethod.id)}
                      >
                        <PaymentMethod paymentMethod={paymentMethod} />
                      </Radio>
                    ))}
                    <Button bsSize="sm" onClick={openModal('AddCreditCard')}>
                      Add a credit card
                    </Button>
                  </>
                )}
              </ConnectedField>
            </>
          )}

          <hr />

          {form.formState.errors._ && <Alert bsStyle="danger">{form.formState.errors._.message}</Alert>}

          <Row>
            <Col md={9} mdPush={3} className="p-b-1 flex">
              <Button
                type="submit"
                bsStyle="primary"
                disabled={timer.isLoading}
                className={classNames({ 'btn-loading': timer.isLoading })}
              >
                Register
              </Button>
              {!!totalCost && (
                <div className="m-l-2 pt-3 flex align-middle justify-center">
                  Your card will be charged&nbsp;
                  <Money amount={totalCost} />.
                </div>
              )}
            </Col>
          </Row>
        </form>
      </FormProvider>
      <AddCreditCardModal onCreditCardCreate={handleCreditCard} />
    </>
  )
}

export default EventRegistrationForm
